<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { authComputed } from "@/state/helpers";
import store from "@/state/store";
import Multiselect from "vue-multiselect";

export default {
  beforeRouteEnter(to, from, next) {
    const userType = store.getters["auth/getUser"].user_type;
    if (userType === 2 || userType === 3) {
      next();
    } else {
      alert("You are not allowed to access this page. You will be redirected.");
      next("/");
    }
  },
  page: {
    title: "Travel Request",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    ...authComputed,
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  data() {
    return {
      title: "Travel Request",
      items: [
        {
          text: "IHUMANE",
        },
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Travel Request",
          active: true,
        },
      ],
      category: 1,
      categoryOptions: [
        { text: "Official", value: 1 },
        { text: "Personal", value: 2 },
      ],
      count: 1,
      trips: [
        {
          id: 0,
          from: null,
          fromEdit: false,
          date: new Date(),
          dateEdit: false,
          airRoad: 1,
          airRoadOptions: [
            { value: 1, text: "Road" },
            { value: 2, text: "Air" },
          ],
          airRoadEdit: false,
          amPm: 1,
          amPmOptions: [
            { value: 1, text: "AM" },
            { value: 2, text: "PM" },
          ],
          amPmEdit: false,
          destination: null,
          destinationEdit: false,
        },
      ],
      staffAssociates: [],
      nonStaffAssociates: [],
      perDiem: null,
      perDiemEdit: false,
      perDiemDays: null,
      perDiemDaysEdit: null,
      currency: "NGN",
      currencyOptions: [
        { value: "NGN", text: "NGN" },
        { value: "GBP", text: "GBP" },
        { value: "USD", text: "USD" },
      ],
      total: 0,
      hotel: 2,
      hotelOptions: [
        { value: 1, text: "Yes" },
        { value: 2, text: "No" },
      ],
      hotels: [
        {
          name: null,
          nameEdit: false,
          city: null,
          cityEdit: false,
          country: null,
          countryEdit: false,
          arrivalDate: new Date(),
          arrivalDateEdit: false,
          departureDate: new Date(),
          departureDateEdit: false,
        },
      ],
      hotelCity: "",
      hotelCityEdit: false,
      hotelArrival: new Date(),
      hotelArrivalEdit: false,
      hotelDeparture: new Date(),
      hotelDepartureEdit: false,
      hotelPreferred: null,
      hotelPreferredEdit: false,
      donor: null,
      donors: [
        { value: null, text: "Please select grant code", disabled: true },
      ],
      expense: [{ code: null }],
      expenses: [
        { value: null, text: "Please select expenses", disabled: true },
      ],
      fetchingExpenses: false,
      confirmTA: false,
      purpose: "",
      start: null,
      end: null,
      employees: [],
      sectors: [],
      sector: null,
      locations: [],
      location: null,
      countryCodes: [],
      countryCode: null,
      tripType: "domestic",
      tripTypeOptions: [
        { value: "domestic", text: "DOMESTIC" },
        { value: "international", text: "INTERNATIONAL" },
      ],
      uploadFieldName: "file",
      uploadFiles: [],
    };
  },
  methods: {
    fetchDonors() {
      this.apiGet(this.ROUTES.donor, "Get Donors Error").then(async (res) => {
        const { data } = res;
        this.donors = [
          { value: null, text: "Please select grant code", disabled: true },
        ];
        await data.forEach((donor) => {
          this.donors.push({
            value: donor.donor_id,
            text: `${donor.donor_code} (${donor.donor_description})`,
            disabled: false,
          });
        });
      });
    },
    fetchEmployees() {
      this.apiGet(this.ROUTES.employee, "Get Employees Error").then((res) => {
        this.employees = [];
        const { data } = res;
        data.forEach((employee) => {
          this.employees.push({
            value: employee.emp_id,
            text: `${employee.emp_unique_id}`,
          });
        });
      });
    },
    fetchSectors() {
      this.apiGet(this.ROUTES.department, "Get Sectors Error").then((res) => {
        this.sectors = [];
        const { data } = res;
        data.departments.forEach((sector) => {
          this.sectors.push({
            value: sector.department_id,
            text: `${sector.department_name} - ${sector.d_t3_code}`,
          });
        });
      });
    },
    fetchLocations() {
      this.apiGet(this.ROUTES.location, "Get Locations Error").then((res) => {
        this.locations = [];
        const { data } = res;
        data.forEach((location) => {
          this.locations.push({
            value: location.location_id,
            text: `${location.location_name} - ${location.l_t6_code}`,
          });
        });
      });
    },
    fetchCountryCodes() {
      this.apiGet(this.ROUTES.countryCodes, "Get Country Codes Error").then(
        (res) => {
          this.countryCodes = [];
          const { data } = res;
          data.forEach((countryCode) => {
            this.countryCodes.push({
              value: countryCode.cc_id,
              text: `${countryCode.cc_code} - ${countryCode.cc_name}`,
            });
          });
        }
      );
    },
    employeeLabel({ text }) {
      return `${text}`;
    },
    getExpenses() {
      this.fetchingExpenses = true;
      this.expenses = [
        { value: null, text: "Please select expenses", disabled: true },
      ];
      const url = `${this.ROUTES.grantChart}/grant/donor/${this.donor}`;
      this.apiGet(url, "Get Expenses Error").then(async (res) => {
        const { data } = res;

        await data.forEach((expense) => {
          this.expenses.push({
            value: expense.gc_id,
            text: `${expense.gc_expense}: ${expense.gc_account_code}`,
            disabled: false,
          });
        });
        this.fetchingExpenses = false;
      });
    },
    addStaffAssociate() {
      this.staffAssociates.push({
        empId: null,
        staffId: null,
        name: null,
        phone: null,
      });
    },
    delStaffAssociate(index) {
      this.staffAssociates.splice(index, 1);
    },
    setStaffAssociate(event, index) {
      if (event) {
        const duplicate = this.staffAssociates.find(
          (associate) => associate.empId === event.value
        );

        if (duplicate) {
          this.staffAssociates.splice(index, 1);
        } else {
          this.staffAssociates[index].empId = event.value;
          const url = `${this.ROUTES.employee}/get-employee/${event.value}`;
          this.apiGet(url, "Get Employee Error").then((res) => {
            const { emp_first_name, emp_last_name, emp_phone_no } = res.data;
            this.staffAssociates[
              index
            ].name = `${emp_first_name} ${emp_last_name}`;
            this.staffAssociates[index].phone = `${emp_phone_no}`;
          });
        }
      } else {
        this.staffAssociates[index].name = "";
        this.staffAssociates[index].phone = "";
      }
    },
    addNonStaffAssociate() {
      this.nonStaffAssociates.push({
        name: null,
        phone: null,
      });
    },
    delNonStaffAssociate(index) {
      this.nonStaffAssociates.splice(index, 1);
    },
    addHotel() {
      this.hotels.push({
        name: null,
        nameEdit: false,
        city: null,
        cityEdit: false,
        country: null,
        countryEdit: false,
        arrivalDate: new Date(),
        arrivalDateEdit: false,
        departureDate: new Date(),
        departureDateEdit: false,
      });
    },
    delHotel(index) {
      if (index > 0) this.hotels.splice(index, 1);
    },
    addTrip() {
      this.trips.push({
        id: this.count++,
        from: null,
        fromEdit: false,
        date: new Date(),
        dateEdit: false,
        airRoad: 1,
        airRoadOptions: [
          { value: 1, text: "Road" },
          { value: 2, text: "Air" },
        ],
        airRoadEdit: false,
        amPm: 1,
        amPmOptions: [
          { value: 1, text: "AM" },
          { value: 2, text: "PM" },
        ],
        amPmEdit: false,
        destination: null,
        destinationEdit: false,
      });
    },
    delTrip(index) {
      if (index > 0) {
        this.trips.splice(index, 1);
      }
    },
    addExp() {
      this.expense.push({ code: null });
    },
    delExp(index) {
      if (index > 0) this.expense.splice(index, 1);
    },
    getTotal() {
      let total = 0;
      if (this.perDiem && this.perDiemDays) {
        let rate = parseFloat(this.perDiem.replace(/,/g, ""));
        let days = parseFloat(this.perDiemDays.replace(/,/g, ""));
        total = rate * days;
      }
      this.total = total.toString();
    },
    launchFilePicker() {
      this.$refs.file.click();
    },
    async onFileChange(fieldName, files) {
      this.uploadFiles = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          this.uploadFiles.push(files[i]);
        }
      } else {
        this.$bvToast.toast("Please select at least one file to upload", {
          title: "No Files Selected",
          toaster: "b-toaster-top-right",
          appendToast: true,
          variant: "warning",
        });
      }
    },
    deleteFile(index) {
      this.uploadFiles.splice(index, 1);
    },
    submit() {
      let data = {};

      data.employee = this.getEmployee.emp_id;
      data.travel_category = this.category;

      let per_diem = 0;
      if (this.category === 1) {
        if (this.perDiem) {
          per_diem = parseInt(this.perDiem.replace(/,/g, ""));
          data.per_diem = per_diem;
        }

        if (!data.per_diem) {
          this.apiFormHandler("Per Diem is required.");
          return;
        }
      }

      data.currency = this.currency;

      let t2_code = [];
      this.expense.forEach((exp) => {
        if (exp.code) t2_code.push({ code: exp.code });
      });
      if (this.category === 1) {
        data.t2_code = t2_code;
      }

      data.hotel = this.hotel;
      if (this.hotel === 1) {
        // data.city = this.hotelCity;
        // data.arrival_date = this.hotelArrival;
        // data.departure_date = this.hotelDeparture;
        // data.preferred_hotel = this.hotelPreferred;
        data.hotels = this.hotels.map((hotel) => {
          return {
            name: hotel.name,
            city: hotel.city,
            country: hotel.country,
            arrival_date: hotel.arrivalDate,
            departure_date: hotel.departureDate,
          };
        });

        if (!data.hotels.length) {
          this.apiFormHandler("A minimum of one hotel is required.");
          return;
        }
      }

      data.purpose = this.purpose;
      if (!data.purpose) {
        this.apiFormHandler("Travel purpose is required.");
        return;
      }

      data.start_date = this.start;
      data.end_date = this.end;
      if (!data.start_date || !data.end_date) {
        this.apiFormHandler("Both start and end dates are required");
        return;
      }

      if (this.category === 1) {
        data.t1_code = this.donor;
        if (this.total) {
          data.total = parseInt(this.total.replace(/,/g, ""));
        } else {
          this.apiFormHandler("Total is required. Please, set No. Days.");
          return;
        }
      }

      let breakdown = [];
      let travellers = [];

      this.trips.forEach((trip) => {
        breakdown.push({
          depart_from: trip.from,
          actual_date: trip.date,
          means: trip.airRoad,
          prompt: trip.amPm,
          destination: trip.destination,
        });
      });

      data.breakdown = breakdown;

      this.staffAssociates.forEach((associate) => {
        travellers.push({
          name: associate.name,
          phone: associate.phone,
          t7: associate.staffId.text,
        });
      });

      this.nonStaffAssociates.forEach((associate) => {
        travellers.push({
          name: associate.name,
          phone: associate.phone,
        });
      });

      if (this.category === 1) {
        if (travellers.length <= 0) {
          this.apiFormHandler("A minimum of one traveller is required.");
          return;
        }

        data.travellers = travellers;
      }

      data.d3_id = `${this.sector?.value}`;
      if (this.category === 1 && !data.d3_id) {
        this.apiFormHandler("Sector is required.");
        return;
      }

      data.d4_id = `${this.location?.value}`;
      if (this.category === 1 && !data.d4_id) {
        this.apiFormHandler("Location is required.");
        return;
      }

      data.d5_id = `${this.countryCode?.value}`;
      if (this.category === 1 && !data.d5_id) {
        this.apiFormHandler("Country code is required.");
        return;
      }

      data.trip_type = this.tripType;

      console.log(data);

      let url = `${this.ROUTES.travelApplication}/new-travel-application`;
      this.confirmTA = false;
      this.apiPost(url, data, "Add Travel Application").then((res) => {
        const { data } = res;
        const { message, travelapp_id } = data;
        if (!this.uploadFiles.length) {
          this.$router.push({ name: "travel-requests" }).then(() => {
            this.apiResponseHandler(message, "Travel Application Submitted");
          });
        } else {
          const formData = new FormData();
          this.uploadFiles.forEach((file) => {
            formData.append("attachments", file);
          });
          url = `${this.ROUTES.travelApplication}/upload-attachments/${travelapp_id}`;
          this.apiPost(url, formData, "Add Travel Application").then((res) => {
            const { data } = res;
            this.$router.push({ name: "travel-requests" }).then(() => {
              this.apiResponseHandler(data, "Travel Application Submitted");
            });
          });
        }
      });
    },
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },
  watch: {
    perDiem: function (newValue) {
      const result = newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (this.perDiem = result));
    },
    perDiemDays: function (newValue) {
      const result = newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (this.perDiemDays = result));
    },
    total: function (newValue) {
      const result = newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (this.total = result));
    },
  },
  mounted() {
    // this.fetchDonors();
    this.fetchEmployees();
    this.fetchSectors();
    this.fetchLocations();
    this.fetchCountryCodes();
  },
};
</script>
<style>
.file-uploads {
  padding: 3em;
  border: 1px dashed #1cbb8c;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
}
.file-detail {
  border: 1px solid #ced4da;
  padding: 0.5em 1em;
  border-radius: 5px;
}
</style>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="d-flex justify-content-between align-items-end mb-3">
      <b-form-group class="mb-0">
        <b-form-radio-group
          id="btn-radios-1"
          v-model="category"
          :options="categoryOptions"
          buttons
          button-variant="outline-success"
        />
      </b-form-group>
      <b-button
        class="btn btn-success"
        @click="$router.push({ name: 'travel-requests' })"
      >
        <i class="mdi mdi-plus mr-2"></i>
        View Travel Requests
      </b-button>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="p-3 bg-light mb-4">
              <h5 class="font-size-14 mb-0" v-if="category === 1">
                Official Travel Authorization / Per Diem Form
              </h5>
              <h5 class="font-size-14 mb-0" v-else>
                Personal Travel Authorization
              </h5>
            </div>
            <form>
              <div class="row">
                <div class="col-lg-8">
                  <div class="form-group">
                    <label for="">
                      Purpose of Travel
                      <small class="text-muted">(Description)</small>
                    </label>
                    <input class="form-control" v-model="purpose" />
                  </div>
                  <div class="form-group">
                    <label for="">
                      Dates of Travel
                      <small class="text-muted">(TO & FRO)</small>
                    </label>
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label for=""> Start </label>
                          <input
                            type="date"
                            v-model="start"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label for=""> End </label>
                          <input
                            type="date"
                            v-model="end"
                            class="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div v-if="category === 1">
                    <div class="form-group">
                      <label for="">Program / Charge Codes</label>
                      <div class="row">
                        <div class="col-lg-3">
                          <div class="form-group">
                            <label class="pt-2" for="">
                              D1 <small class="text-muted">(Grant Code)</small>
                            </label>
                          </div>
                        </div>
                        <div class="col-lg-9">
                          <div class="form-group">
                            <input
                              type="text"
                              v-model="donor"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-3">
                          <div class="form-group">
                            <label class="pt-2" for="">
                              D2 <small class="text-muted">(Expense)</small>
                            </label>
                          </div>
                        </div>
                        <div class="col-lg-9">
                          <div
                            v-for="(exp, index) in expense"
                            :key="index"
                            class="row"
                          >
                            <div class="col-lg-9">
                              <div class="form-group">
                                <input
                                  type="text"
                                  v-model="exp.code"
                                  class="form-control"
                                />
                              </div>
                            </div>
                            <div class="col-lg-3">
                              <b-button
                                @click="addExp"
                                variant="success"
                                size="sm"
                                v-if="index === 0"
                              >
                                ADD
                              </b-button>
                              <b-button
                                @click="delExp(index)"
                                variant="danger"
                                size="sm"
                                v-else
                              >
                                DEL
                              </b-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-4" v-if="category === 1">
                <div class="col-lg-3">
                  <label>
                    Sector <small class="text-muted">(D3 & D6)</small>
                  </label>
                  <multiselect
                    v-model="sector"
                    :options="sectors"
                    :custom-label="employeeLabel"
                  />
                </div>
                <div class="col-lg-3">
                  <label>
                    Location <small class="text-muted">(D4)</small>
                  </label>
                  <multiselect
                    v-model="location"
                    :options="locations"
                    :custom-label="employeeLabel"
                  />
                </div>
                <div class="col-lg-3">
                  <label>
                    Country Code <small class="text-muted">(D5)</small>
                  </label>
                  <multiselect
                    v-model="countryCode"
                    :options="countryCodes"
                    :custom-label="employeeLabel"
                  />
                </div>
                <div class="col-lg-3">
                  <label> Trip Type </label>
                  <b-form-group class="mb-0">
                    <b-form-radio-group
                      size="md"
                      id="btn-radios-1"
                      v-model="tripType"
                      :options="tripTypeOptions"
                      buttons
                      button-variant="outline-secondary"
                    />
                  </b-form-group>
                </div>
              </div>

              <div class="row mb-5" v-if="category === 1">
                <div class="col-lg-7">
                  <div
                    class="d-flex justify-content-between align-items-center mb-2"
                  >
                    <label> Staff Travellers </label>
                    <b-button
                      class="btn btn-sm btn-success"
                      @click="addStaffAssociate"
                    >
                      ADD
                    </b-button>
                  </div>
                  <div
                    class="row mb-2"
                    v-for="(associate, index) in staffAssociates"
                    :key="index"
                  >
                    <div class="col-lg-3">
                      <label> Employee ID </label>
                      <multiselect
                        v-model="associate.staffId"
                        :options="employees"
                        :custom-label="employeeLabel"
                        @input="setStaffAssociate($event, index)"
                      />
                    </div>
                    <div class="col-lg-4">
                      <label> Name </label>
                      <input
                        v-model="associate.name"
                        type="text"
                        class="form-control"
                      />
                    </div>
                    <div class="col-lg-4">
                      <label> Phone </label>
                      <input
                        v-model="associate.phone"
                        type="text"
                        class="form-control"
                      />
                    </div>
                    <div class="col-lg-1 align-content-end pb-1">
                      <b-button
                        class="btn btn-sm btn-danger float-right"
                        @click="delStaffAssociate(index)"
                      >
                        DEL
                      </b-button>
                    </div>
                  </div>
                </div>
                <div class="col-lg-5">
                  <div
                    class="d-flex justify-content-between align-items-center mb-2"
                  >
                    <label> Non Staff Travellers </label>
                    <b-button
                      class="btn btn-sm btn-success"
                      @click="addNonStaffAssociate"
                    >
                      ADD
                    </b-button>
                  </div>
                  <div
                    class="row mb-2"
                    v-for="(associate, index) in nonStaffAssociates"
                    :key="index"
                  >
                    <div class="col-lg-6">
                      <label> Name </label>
                      <input
                        v-model="associate.name"
                        type="text"
                        class="form-control"
                      />
                    </div>
                    <div class="col-lg-5">
                      <label> Phone </label>
                      <input
                        v-model="associate.phone"
                        type="text"
                        class="form-control"
                      />
                    </div>
                    <div class="col-lg-1 align-content-end pb-1">
                      <b-button
                        class="btn btn-sm btn-danger float-right"
                        @click="delNonStaffAssociate(index)"
                      >
                        DEL
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <b-table-simple hover responsive bordered outlined>
                    <b-thead head-variant="light">
                      <b-tr>
                        <b-th>DEPART FROM</b-th>
                        <b-th>DATE</b-th>
                        <b-th>AIR/ROAD</b-th>
                        <b-th>AM/PM</b-th>
                        <b-th>DESTINATION</b-th>
                        <b-th></b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr v-for="(trip, index) in trips" :key="index">
                        <b-td
                          @click="trip.fromEdit = true"
                          style="cursor: pointer; width: 30%"
                        >
                          <input
                            size="sm"
                            type="text"
                            v-if="trip.fromEdit"
                            v-model="trip.from"
                            @blur="
                              trip.fromEdit = false;
                              $emit('update');
                            "
                            @keyup.enter="
                              trip.fromEdit = false;
                              $emit('update');
                            "
                            v-focus
                            class="form-control form-control-sm"
                          />
                          <span v-else>
                            <span v-if="trip.from">
                              {{ trip.from }}
                            </span>
                            <em style="font-size: 0.8em" v-else>
                              click to enter departure point...
                            </em>
                          </span>
                        </b-td>
                        <b-td
                          style="cursor: pointer; width: 15%"
                          @click="trip.dateEdit = true"
                        >
                          <input
                            v-if="trip.dateEdit"
                            v-model="trip.date"
                            @blur="
                              trip.dateEdit = false;
                              $emit('update');
                            "
                            @input="
                              trip.dateEdit = false;
                              $emit('update');
                            "
                            @keyup.enter="
                              trip.dateEdit = false;
                              $emit('update');
                            "
                            v-focus
                            type="date"
                            class="form-control form-control-sm"
                          />
                          <span v-else>
                            {{ new Date(trip.date).toDateString() }}
                          </span>
                        </b-td>
                        <b-td style="width: 10%">
                          <b-form-group class="mb-0">
                            <b-form-radio-group
                              size="sm"
                              id="btn-radios-1"
                              v-model="trip.airRoad"
                              :options="trip.airRoadOptions"
                              buttons
                              button-variant="outline-secondary"
                            />
                          </b-form-group>
                        </b-td>
                        <b-td style="width: 10%">
                          <b-form-group class="mb-0">
                            <b-form-radio-group
                              size="sm"
                              id="btn-radios-1"
                              v-model="trip.amPm"
                              :options="trip.amPmOptions"
                              buttons
                              button-variant="outline-secondary"
                            />
                          </b-form-group>
                        </b-td>
                        <b-td
                          @click="trip.destinationEdit = true"
                          style="cursor: pointer; width: 30%"
                        >
                          <input
                            type="text"
                            v-if="trip.destinationEdit"
                            v-model="trip.destination"
                            @blur="
                              trip.destinationEdit = false;
                              $emit('update');
                            "
                            @keyup.enter="
                              trip.destinationEdit = false;
                              $emit('update');
                            "
                            v-focus
                            class="form-control form-control-sm"
                          />
                          <span v-else>
                            <span v-if="trip.destination">
                              {{ trip.destination }}
                            </span>
                            <em style="font-size: 0.8em" v-else>
                              click to enter arrival point...
                            </em>
                          </span>
                        </b-td>
                        <b-td style="width: 5%">
                          <b-button
                            class="btn btn-sm btn-danger"
                            v-if="index > 0"
                            @click="delTrip(index)"
                          >
                            DEL
                          </b-button>
                          <b-button
                            v-else
                            class="btn btn-sm btn-success"
                            @click="addTrip"
                          >
                            ADD
                          </b-button>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </div>
              </div>
              <div class="row mt-4" v-if="category === 1">
                <div class="col-12">
                  <b-table-simple responsive bordered outlined>
                    <b-thead head-variant="light">
                      <b-tr>
                        <b-th>PER DIEM RATE</b-th>
                        <b-th>NO. DAYS</b-th>
                        <b-th>CURRENCY</b-th>
                        <b-th class="text-center">TOTAL</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr>
                        <b-td
                          style="cursor: pointer; width: 30%"
                          @click="perDiemEdit = true"
                        >
                          <input
                            type="text"
                            v-if="perDiemEdit"
                            v-model="perDiem"
                            @blur="
                              perDiemEdit = false;
                              $emit('update');
                            "
                            @keyup.enter="
                              perDiemEdit = false;
                              $emit('update');
                            "
                            @input="getTotal"
                            v-focus
                            class="form-control form-control-sm"
                          />
                          <span v-else>
                            <span v-if="perDiem">
                              {{ perDiem }}
                            </span>
                            <em style="font-size: 0.8em" v-else>
                              click to enter per diem rate...
                            </em>
                          </span>
                        </b-td>
                        <b-td
                          style="cursor: pointer; width: 20%"
                          @click="perDiemDaysEdit = true"
                        >
                          <input
                            type="text"
                            v-if="perDiemDaysEdit"
                            v-model="perDiemDays"
                            @blur="
                              perDiemDaysEdit = false;
                              $emit('update');
                            "
                            @keyup.enter="
                              perDiemDaysEdit = false;
                              $emit('update');
                            "
                            @input="getTotal"
                            v-focus
                            class="form-control form-control-sm"
                          />
                          <span v-else>
                            <span v-if="perDiemDays">
                              {{ perDiemDays }}
                            </span>
                            <em style="font-size: 0.8em" v-else>
                              click to enter number of days...
                            </em>
                          </span>
                        </b-td>
                        <b-td style="cursor: pointer; width: 20%">
                          <b-form-group class="mb-0">
                            <b-form-radio-group
                              size="sm"
                              id="btn-radios-1"
                              v-model="currency"
                              :options="currencyOptions"
                              buttons
                              button-variant="outline-secondary"
                            />
                          </b-form-group>
                          <span> </span>
                        </b-td>
                        <b-td
                          style="
                            cursor: pointer;
                            width: 30%;
                            text-align: center;
                          "
                        >
                          {{ total }}
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-lg-3">
                  <label>Hotel Required?</label>
                  <b-form-group class="mb-0">
                    <b-form-radio-group
                      size="md"
                      id="btn-radios-1"
                      v-model="hotel"
                      :options="hotelOptions"
                      buttons
                      button-variant="outline-secondary"
                    />
                  </b-form-group>
                </div>
              </div>

              <div class="row mt-5" v-if="hotel === 1">
                <div class="col-12">
                  <b-table-simple responsive bordered outlined>
                    <b-thead head-variant="light">
                      <b-tr>
                        <b-th>HOTEL NAME</b-th>
                        <b-th>CITY</b-th>
                        <b-th>COUNTRY</b-th>
                        <b-th>ARRIVAL DATE</b-th>
                        <b-th>DEPARTURE DATE</b-th>
                        <b-th></b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr v-for="(hotel, index) in hotels" :key="index">
                        <b-td
                          style="cursor: pointer; width: 20%"
                          @click="hotel.nameEdit = true"
                        >
                          <input
                            size="sm"
                            type="text"
                            v-if="hotel.nameEdit"
                            v-model="hotel.name"
                            @blur="
                              hotel.nameEdit = false;
                              $emit('update');
                            "
                            @keyup.enter="
                              hotel.nameEdit = false;
                              $emit('update');
                            "
                            v-focus
                            class="form-control form-control-sm"
                          />
                          <span v-else>
                            <span v-if="hotel.name">
                              {{ hotel.name }}
                            </span>
                            <em style="font-size: 0.8em" v-else>
                              click to enter hotel name...
                            </em>
                          </span>
                        </b-td>
                        <b-td
                          style="cursor: pointer; width: 20%"
                          @click="hotel.cityEdit = true"
                        >
                          <input
                            size="sm"
                            type="text"
                            v-if="hotel.cityEdit"
                            v-model="hotel.city"
                            @blur="
                              hotel.cityEdit = false;
                              $emit('update');
                            "
                            @keyup.enter="
                              hotel.cityEdit = false;
                              $emit('update');
                            "
                            v-focus
                            class="form-control form-control-sm"
                          />
                          <span v-else>
                            <span v-if="hotel.city">
                              {{ hotel.city }}
                            </span>
                            <em style="font-size: 0.8em" v-else>
                              click to enter city...
                            </em>
                          </span>
                        </b-td>
                        <b-td
                          style="cursor: pointer; width: 20%"
                          @click="hotel.countryEdit = true"
                        >
                          <input
                            size="sm"
                            type="text"
                            v-if="hotel.countryEdit"
                            v-model="hotel.country"
                            @blur="
                              hotel.countryEdit = false;
                              $emit('update');
                            "
                            @keyup.enter="
                              hotel.countryEdit = false;
                              $emit('update');
                            "
                            v-focus
                            class="form-control form-control-sm"
                          />
                          <span v-else>
                            <span v-if="hotel.country">
                              {{ hotel.country }}
                            </span>
                            <em style="font-size: 0.8em" v-else>
                              click to enter country...
                            </em>
                          </span>
                        </b-td>
                        <b-td
                          style="cursor: pointer; width: 15%"
                          @click="hotel.arrivalDateEdit = true"
                        >
                          <input
                            v-if="hotel.arrivalDateEdit"
                            v-model="hotel.arrivalDate"
                            @blur="
                              hotel.arrivalDateEdit = false;
                              $emit('update');
                            "
                            @input="
                              hotel.arrivalDateEdit = false;
                              $emit('update');
                            "
                            @keyup.enter="
                              hotel.arrivalDateEdit = false;
                              $emit('update');
                            "
                            v-focus
                            type="date"
                            class="form-control form-control-sm"
                          />
                          <span v-else>
                            {{ new Date(hotel.arrivalDate).toDateString() }}
                          </span>
                        </b-td>
                        <b-td
                          style="cursor: pointer; width: 15%"
                          @click="hotel.departureDateEdit = true"
                        >
                          <input
                            v-if="hotel.departureDateEdit"
                            v-model="hotel.departureDate"
                            @blur="
                              hotel.departureDateEdit = false;
                              $emit('update');
                            "
                            @input="
                              hotel.departureDateEdit = false;
                              $emit('update');
                            "
                            @keyup.enter="
                              hotel.departureDateEdit = false;
                              $emit('update');
                            "
                            v-focus
                            type="date"
                            class="form-control form-control-sm"
                          />
                          <span v-else>
                            {{ new Date(hotel.departureDate).toDateString() }}
                          </span>
                        </b-td>
                        <b-td style="width: 5%">
                          <b-button
                            class="btn btn-sm btn-danger"
                            v-if="index > 0"
                            @click="delHotel(index)"
                          >
                            DEL
                          </b-button>
                          <b-button
                            v-else
                            class="btn btn-sm btn-success"
                            @click="addHotel"
                          >
                            ADD
                          </b-button>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </div>
              </div>

              <div class="row my-4" v-if="category === 1">
                <div class="col-lg-12">
                  <label>Attachments</label>
                  <input
                    type="file"
                    ref="file"
                    multiple
                    :name="uploadFieldName"
                    @change="
                      onFileChange($event.target.name, $event.target.files)
                    "
                    style="display: none"
                  />
                  <div
                    class="file-uploads mb-3 w-100"
                    @click="launchFilePicker()"
                  >
                    <p class="mb-0 text-muted">Click here to upload files</p>
                  </div>
                  <div v-if="uploadFiles.length > 0">
                    <div class="alert alert-info mb-3">
                      You've chosen the following attachments to include in your
                      application.
                    </div>
                    <div
                      class="file-detail d-flex justify-content-between align-items-center mt-3"
                      v-for="(file, index) in uploadFiles"
                      :key="index"
                    >
                      <small>
                        <span>{{ file.name }}</span>
                        <br />
                        <span>{{ file.size | getFileSize }}</span>
                      </small>
                      <button
                        class="btn btn-sm btn-danger"
                        @click="deleteFile(index)"
                      >
                        DEL
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <b-button
                v-if="!submitting"
                @click="confirmTA = true"
                class="btn btn-success btn-block mt-4"
              >
                Submit
              </b-button>
              <b-button v-else disabled class="btn btn-success btn-block mt-4">
                Submitting...
              </b-button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      v-model="confirmTA"
      title="Travel Request"
      centered
      no-close-on-esc
      no-close-on-backdrop
      title-class="text-black font-18"
      body-class="p-3"
      hide-footer
      hide-header
    >
      <div class="text-center">
        <i
          class="mdi mdi-alert-octagon-outline text-success"
          style="font-size: 4em"
        />
        <h5 class="mt-n3 text-success">Are you sure?</h5>
      </div>
      <div class="alert alert-success mt-4">
        Please ensure all fields of the Travel Authorization form are filled
        correctly before submission.
      </div>
      <b-row>
        <b-col lg="6">
          <a
            href="javascript: void(0);"
            class="dropdown-icon-item"
            @click="confirmTA = false"
          >
            <i class="dripicons-wrong" style="font-size: 2em"></i>
            <span>Cancel</span>
          </a>
        </b-col>
        <b-col lg="6" class="mt-lg-0 mt-3">
          <a
            href="javascript: void(0);"
            class="dropdown-icon-item"
            @click="submit"
          >
            <i class="dripicons-checkmark" style="font-size: 2em"></i>
            <span>Confirm</span>
          </a>
        </b-col>
      </b-row>
    </b-modal>
  </Layout>
</template>
